import React from 'react';
import './App.css';
import sunrise from './img/bg/sunrise.jpg'
import bullets from './img/shps/bullets/1.svg'
import bullets2 from './img/shps/bullets/2.svg'
import bullets3 from './img/shps/bullets/3.svg'
import webpack from './img/demo/logo/webpack.svg'
import logLight from './img/logo-light.png'

function App() {
  return (
      <main className="overflow-hidden">
        <header className="header section text-contrast overlay overlay-primary alpha-7 parallax cover"
                style={{backgroundImage: `url(${sunrise})`}}>
          <div className="container-fluid pb-9">
            <div className="row text-center">
              <div className="col-12 col-lg-6 mx-auto"><img src={logLight} alt=""/>
                <h1 className="text-contrast bold display-4 display-md-3 text-capitalize mt-5">On the Job Support</h1>
                <p className="lead bold">We help you Succeed by Mentoring and Supporting</p>
                <p className="handwritten highlight font-md">Feeling pumped?</p><a
                  className="btn btn-danger btn-rounded btn-lg"><i className="fa fa-paper-plane me-2"></i> Check out
                pricing</a>
              </div>
            </div>
          </div>
        </header>
        <section className="section mt-n6">
          <div className="container pt-0">
            <div className="shadow bg-contrast p-4 rounded">
              <div className="row gap-y text-center">
                <div className="col-12 col-md-4 py-4 px-5 d-flex flex-column">
                  <div className="icon-shape mb-4"><img src={bullets}
                                                        className="shape shape-xxl icon-xxl" alt=""/>
                    <div
                        className="rounded-circle bg-primary shadow-3 p-3 d-flex align-items-center justify-content-center shadow icon-xl center-xy">
                      <img src={webpack} className="img-responsive" alt=""/></div>
                  </div>
                  <h4 className="text-darker bold mt-3">I.T Audit</h4>
                  <p className="mt-4">Highly skilled I.T Audit practitioners with many years of experience in the
                    industry</p>
                  <div className="card-body"><a href="javascript:;" className="btn btn-rounded btn-outline-primary">Learn
                    More <i className="fa fa-long-arrow-alt-right ms-3"></i></a></div>
                </div>
                <div className="col-12 col-md-4 py-4 px-5 d-flex flex-column">
                  <div className="icon-shape mb-4"><img src={bullets2}
                                                        className="shape shape-xxl icon-xxl" alt=""/>
                    <div
                        className="rounded-circle gradient gradient-blue-purple shadow-4 p-3 d-flex align-items-center justify-content-center shadow icon-xl center-xy">
                      <img src={webpack} className="img-responsive" alt=""/></div>
                  </div>
                  <h4 className="text-darker bold mt-3">Software Engineering</h4>
                  <p className="mt-4">We mean, everything from frontend, backend and cloud computing support</p>
                  <div className="card-body"><a href="javascript:;" className="btn btn-rounded btn-outline-primary">Learn
                    More <i className="fa fa-long-arrow-alt-right ms-3"></i></a></div>
                </div>
                <div className="col-12 col-md-4 py-4 px-5 d-flex flex-column">
                  <div className="icon-shape mb-4"><img src={bullets3}
                                                        className="shape shape-xxl icon-xxl" alt=""/>
                    <div
                        className="rounded-circle bg-info shadow-4 p-3 d-flex align-items-center justify-content-center shadow icon-xl center-xy">
                      <img src={webpack} className="img-responsive" alt=""/></div>
                  </div>
                  <h4 className="text-darker bold mt-3">Product Ownership</h4>
                  <p className="mt-4">We hold your hand throughout your project. We help you focus on big picture</p>
                  <div className="card-body"><a href="javascript:;" className="btn btn-rounded btn-outline-primary">Learn
                    More <i className="fa fa-long-arrow-alt-right ms-3"></i></a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <div className="">
            <div className="section-heading text-center">
              <div className="col-md-12">
                <h1 className="bold display-md-4 text-secondary mb-4">Pricing plans</h1>
                <p className="lead text-secondary">Simple and affordable pricing plans. Guaranteed Money back in 1
                  week</p>
              </div>
            </div>

          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="card text-center">
                  <div className="pricing card-header p-5 bg-light d-flex align-items-center flex-column">
                    <h4 className="bold">Basic</h4>
                    <div className="pricing-value"><span className="price text-dark">3000</span></div>
                    <p>Suitable for beginners and early career professionals. Appropriate for entry level folks</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item strike-through">After work hours</li>
                    <li className="list-group-item strike-through">Specialized Training</li>
                    <li className="list-group-item strike-through">In person Tutor</li>
                    <li className="list-group-item">Communicate via discord</li>
                    <li className="list-group-item">Documentation</li>
                  </ul>
                  <div className="card-body"><a href="javascript:;" className="btn btn-rounded btn-outline-primary">Buy
                    now <i className="fa fa-long-arrow-alt-right ms-3"></i></a></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card text-center">
                  <div
                      className="pricing card-header p-5 bg-primary text-contrast d-flex align-items-center flex-column">
                    <h4 className="bold text-contrast">Intermediate</h4>
                    <div className="pricing-value"><span className="price text-contrast">3500</span></div>
                    <p>Folks who are more advanced than a beginner typically 0 - 1 years experience. Or career
                      switchers</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item strike-through">After work hours</li>
                    <li className="list-group-item">Specialized Training</li>
                    <li className="list-group-item">In person Tutor</li>
                    <li className="list-group-item">Communicate via discord</li>
                    <li className="list-group-item">Documentation</li>
                  </ul>
                  <div className="card-body"><a href="javascript:;" className="btn btn-rounded btn-primary">Buy now <i
                      className="fa fa-long-arrow-alt-right ms-3"></i></a></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card text-center">
                  <div className="pricing card-header p-5 bg-light d-flex align-items-center flex-column">
                    <h4 className="bold">Advance</h4>
                    <div className="pricing-value"><span className="price text-dark">4000</span></div>
                    <p>You are doing great and feeling pretty confidence in what you do. But you need to make that big
                      impact</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">After work hours</li>
                    <li className="list-group-item">Specialized Training</li>
                    <li className="list-group-item">In person Tutor</li>
                    <li className="list-group-item">Communicate via discord</li>
                    <li className="list-group-item">Documentation</li>
                  </ul>
                  <div className="card-body"><a href="javascript:;" className="btn btn-rounded btn-outline-primary">Buy
                    now <i className="fa fa-long-arrow-alt-right ms-3"></i></a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-dark">
            <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
            </svg>
          </div>
        </div>
        <footer className="site-footer section text-center block bg-dark text-contrast">
          <div className="container pb-4">
            <div className="section-heading"><i className="fa fa-rocket fa-2x"></i>
              <h2 className="bold mb-5 text-contrast">Ready to get started?</h2>
              <p className="lead mb-5">Speak to our agent and get all your questions answered. You can also email us at
                info@techishsupport.com</p>
              <p className="handwritten highlight font-md">Get In Touch Now!!!</p><a href="tel:214-229-6870"
                                                                                     target="_blank"
                                                                                     className="btn btn-lg btn-contrast btn-rounded mt-3 px-4">+1
                (214) 229 6870</a>
            </div>
            <hr className="mt-5"/>
              <div className="row align-items-center">
                <div className="col-md-6 text-center mx-auto"><img src={'img/logo-light.png'} alt="" className="logo"/>
                  <p className="mt-2 mb-0 text-muted small">© 2022 <a className="brand bold" target="_blank"
                                                                      href="https://fasniche.com">Fasniche.com</a>. All
                    Rights Reserved</p>
                </div>
              </div>
          </div>
        </footer>
      </main>
  );
}

export default App;
